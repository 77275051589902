import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"


const HowGetFriendsForLine = ({ location }) => {
  const title =
    "LINE公式アカウントの友だちを増やす方法はこれ！「Twitter×LINE ハイブリッドキャンペーン」"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitter＆LINE併用キャンペーンで友達登録を増やす方法| LINE公式アカウント運用者必見"
          pagedesc="LINE公式アカウントの友だち登録を効率良く増やすにはTwitterとLINEの両方を活用するのがおすすめ。この記事では、その理由と具体的な方法をわかりやすく解説しています。コミュニケーションと拡散力の両軸で効果的な販促を。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-get-friends-for-line.jpg"
          pagepath="/blog/how-get-friends-for-line"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は8分で読むことができます
            </div>
            <h1>
              LINE公式アカウントの友だちを増やす方法はこれ！「Twitter×LINE
              ハイブリッドキャンペーン」
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2021年10月1日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="肩を組んでいる8人の男女の後ろ姿"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-get-friends-for-line.jpg"
                className="w-full"
              />
            </figure>
            <p>
              こんにちは、
              <a
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                PARKLoT（パークロット）
              </a>
              マーケティング担当の島田旬子です！
            </p>
            <p>
              LINEの使用率は全世代での利用率が90.3％と非常に高い利用率を誇ります。
              <small>
                出典：総務省情報通信政策研究所「令和２年度情報通信メディアの利用時間と情報行動に関する調査」
              </small>
            </p>
            <p>
              また、メッセージの開封率も60％と言われていて、平均的なメールマガジンの開封率の10％前後と比べると、なんと6倍にもなります。
            </p>
            <p>
              そのため、LINEをうまく活用できれば非常に強力なマーケティングのツールとなります。
            </p>
            <p>
              LINEをマーケティングに活用にするは、まず「友だち登録」してもらうことが必要ですが、「友だち」を増やすことに苦労されている企業も多いのではないでしょうか。
            </p>
            <p>
              友だち登録を増やす施策といえば「メルマガ」「来店時に登録を促す」「広告を出稿する」「キャンペーンを開催する」などが思い浮かびますが、私がオススメするのは
              <strong>
                Twitterで情報を拡散し、そこからLINE登録に繋げる方法
              </strong>
              です！
            </p>
            <p>その理由や具体的な方法について、詳しく解説したいと思います。</p>
            <p>この記事は次のような人にオススメです！</p>
            <ul className="blog__border-list">
              <li>これからLINE公式アカウントの運用を始めたい</li>
              <li>LINE公式アカウントを運用しているが友だち登録が増えない</li>
              <li>LINEの友だち登録を増やすために、広告を出そうと考えている</li>
            </ul>
            <p>
              今回の記事ではTwitterとLINEの両方を活用して、LINE公式アカウントの友だち登録を増やす方法を解説します。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    LINEの特徴
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    LINEだけで「友だち」を増やすのが難しい
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    Twitterの特徴
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    TwitterからLINEへ誘導する方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ①Twitterキャンペーン参加者に友だち追加URLを送信する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ②落選した方にLINE「友だち追加URL」をお知らせし、LINEでダブルチャンス抽選を行う【オススメ】
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ③抽選結果をLINEで通知する
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    まとめ
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id9" className="toc__link">
                    Twitter×LINE ハイブリッドキャンペーンに必要なツール
                  </a>
                </li>
              </ol>
            </div>
            <h2 id="id1">LINEの特徴</h2>
            <p>初めに、LINE特徴を説明します。</p>
            <p>
              冒頭にも記載しましたが、LINEは国内での利用率が90.3％、そして開封率も60％と非常に高い数値を誇ります。
            </p>
            <p>
              全年代での利用率が高いため、さまざまな商材との相性がよく、開封率も高いためメールマガジンやステップメールをLINEに切り替える企業も増えています。
            </p>
            <p>
              また、LINEは他の人には見られないクローズドなSNSという特徴があるため、
              <strong>
                拡散を期待するのではなく、顧客一人ひとりとの双方向のコミュニケーションを取ることに適したプラットフォーム
              </strong>
              です。
            </p>
            <h2 id="id2">LINEだけで「友だち」を増やすのが難しい</h2>
            <p>
              集客には非常に効果の高いLINEですが、クローズドなSNSという特徴があるため、
              <strong>
                LINE公式アカウントの運用だけでは友だちを増やすことが難しい
              </strong>
              です。
            </p>
            <p>
              <a
                href="https://www.linebiz.com/jp/column/technique/20190426-1/"
                target="_blank"
                rel="noreferrer"
              >
                LINEの公式サイト
              </a>
              では友だちを増やす方法として「来店時の店頭POP」「Webサイトやメルマガ」「友だち登録キャンペーン」が紹介されています。
            </p>
            <p>
              どれも既存顧客に対しては有用な手段ですが、これまで
              <strong>
                自社を全く知らなかった方には友だち登録をしてもらうには不向きな方法
              </strong>
              と言わざるを得ません。
            </p>

            <h2 id="id3">Twitterの特徴</h2>
            <p>次にTwitterの特徴です。</p>
            <p>
              Twitterの特徴の中で、LINEとの大きな違いは
              <strong>ハッシュタグとリツートによる拡散力の高さ</strong>です。
            </p>
            <p>
              Twitterの投稿にはハッシュタグをつけることができ、ハッシュタグを指定して検索することができます。
              ハッシュタグをつけると、つけていないものと比較して、
              <strong>エンゲージメントが2倍になる</strong>
              と言う調査結果があります。
              <small>
                <a
                  href="https://www.quicksprout.com/ultimate-guide-to-hashtags/"
                  target="_blank"
                  rel="noreferrer"
                >
                  出典：Ultimate Guide to Hashtags
                </a>
              </small>
            </p>
            <p>
              ハッシュタグをつけると自社アカウントのフォロワーでなくても、ハッシュタグ検索をしたユーザの目に留まることになり、自社の商品・サービスをより多くの人に知ってもらう機会が増えていきます。
            </p>
            <p>
              また、Twitterには気になった投稿をフォロワーと共有できる、リツイートという機能があります。
            </p>
            <p>
              自社のツイートがリツイートされると、リツイートしたアカウントのフォロワーのタイムラインに表示されることになり、
              <strong>フォロワーのフォロワーへと情報が広がるため</strong>
              、自社アカウントのフォロワー以外の目に留まる機会が生まれます。
            </p>

            <h2 id="id4">TwitterからLINEへ誘導する方法</h2>
            <p>
              このようなLINEとTwitterそれぞれの特徴を活かせるのが、LINEとTwitterを掛け合わせた「ハイブリッドキャンペーン」です。
            </p>
            <p>
              <strong>
                Twitterを情報拡散のブースターとして利用し、そこで獲得したユーザーをLINEへ誘導するのです。
              </strong>
            </p>
            <p>
              では、TwitterからLINEへ誘導する具体的なキャンペーンをご紹介します。
            </p>
            <h3 id="id5">
              ①Twitterキャンペーン参加者に友だち追加URLを送信する
            </h3>
            <ol className="blog__border">
              <li>Twitterでインスタントウィンキャンペーンを開催</li>
              <li>
                応募者全員に抽選通知DMを送り、その中にLINEの「友だち追加URL」を含める
              </li>
              <li>
                DMの「友だち追加URL」から友だち登録した人には特典を用意する
              </li>
            </ol>
            <p>
              2．で「友だち追加URL」を送りますが、そこから登録するかどうかは任意で、応募者の意思に委ねられます。
            </p>
            <p>
              友だち登録率は低くなるものの、応募者に悪印象を持たれにくい方法です。
            </p>
            <h3 id="id6">
              ②落選した方にLINE「友だち追加URL」をお知らせし、LINEでダブルチャンス抽選を行う【オススメ】
            </h3>
            <ol className="blog__border">
              <li>Twitterでインスタントウィンキャンペーンを開催</li>
              <li>
                落選した方が見るページに「友だち追加URL」と、LINE登録すればダブルチャンスに参加できるメッセージを表示
              </li>
              <li>LINE登録後に、LINEでダブルチャンス抽選を行う</li>
            </ol>
            <p>落選した方にのみ、「友だち追加URL」を表示する方法です。</p>
            <p>
              友だち登録するかどうかは任意ですが、
              <strong>
                ダブルチャンスという特典を用意することにより①の方法に比べ、友だち登録を促す効果が高くなります。
              </strong>
            </p>
            <p>
              この方法の場合は、落選した方の不信感を軽減するために、キャンペーン開催ツイートやバナーで「LINE登録でダブルチャンス！」といった内容を表記し、事前にお知らせすることをお勧めします！
            </p>
            <h3 id="id7">③抽選結果をLINEで通知する</h3>
            <ol className="blog__border">
              <li>Twitterでインスタントウィンキャンペーンを開催</li>
              <li>参加者全員にLINEの「友だち追加URL」をDMで通知する</li>

              <li>LINE登録後に、LINEで抽選結果を通知する</li>
            </ol>
            <p>
              抽選結果を見るためにはLINEの友だち登録が必須のため、LINE友だち登録への誘導が一番強い方法になります。
            </p>
            <p>
              ただし、Twitterキャンペーンへの参加のハードルが高くなってしまい、Twitterでの拡散効果が低くなる可能性があります。
            </p>
            <h2 id="id8">まとめ</h2>
            <p>
              今回は、LINE公式アカウントの友だちを増やすのに効果的な「Twitter×LINE
              ハイブリッドキャンペーン」についてご紹介しました。
            </p>
            <p>
              LINEは圧倒的な使用率と開封率を誇るSNSプラットフォームであるため、うまく活用できれば非常に強力なマーケティングツールとなります。
            </p>
            <p>
              「Twitter×LINE
              ハイブリッドキャンペーン」を開催して友だちを増やし、自社の商品・サービスの魅力を多くの方に知ってもらいましょう。
            </p>
            <p>以上、最後まで読んでいただきありがとうございました。</p>
            <h2 id="id9">
              Twitter×LINE ハイブリッドキャンペーンに必要なツール
            </h2>
            <p>
              TwitterとLINEのハイブリッドキャンペーンは非常に強力な方法ですが、キャンペーンを開催するには専用のツールの導入が必要になります。
            </p>
            <p>
              PARKLoTでは、Twitterインスタントウィンキャンペーンが月額5万円〜という、業界最安値で開催できます。
            </p>
            <p>
              また、Twitterキャンペーンツールを提供しているサービスは多数ありますが、TwitterとLINEの両方を提供しているサービスは非常に少ないのが実情です。
            </p>
            <p>
              TwitterとLINEのそれぞれを別のサービスを利用すると、それぞれの調整に非常に労力がかかり、それに比例して価格も高額になります。
            </p>
            <p>
              PARKLoTはLINE
              Bot開発の実績・ノウハウも多数持っているため、開催したいキャンペーンと予算に合わせて最適なLINE
              Botの設計もご提案できます。
            </p>
            <p>
              さらに、少数精鋭のチームで自社開発しているため、企業の予算・商品・サービスに合わせたTwitter×LINE
              ハイブリッドキャンペーンをリーズナブルな価格で開催できます！
            </p>
            <p>
              TwitterとLINEのハイブリッドキャンペーンが開催できるキャンペーンツールをお探しの方は、ぜひ
              <a
                href="https://hub.park-lot.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>
              からお問い合わせください。
            </p>
            <Link to={`/`}>
              <div className="blog__text-center">
                <img
                  layout="constrained"
                  placeholder="tracedSVG"
                  alt="PARKLoT(パークロット)"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/brand/blue.png"
                  maxwidth={300}
                  className="m-auto"
                ></img>
              </div>
            </Link>
            <br />
            <HubSpotFormComponent />
            <br />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="junko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default HowGetFriendsForLine
